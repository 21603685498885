import React, { ReactElement, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorMessage, FormikProps, FormikValues, getIn } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Translate from '../../../components/service/Translate';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { duplicateProduct } from '../ProductSlice';
import { Campaign } from '../../Campaign/CampaignType';
import { selectCampaignsBySingularSeason } from '../../Campaign/selectors';
import { fetchAllCampaigns } from '../../Campaign/CampaignSlice';
import ValidationErrors from '../../../ValidationErrors';
import Form from '../../../components/Form';

interface DuplicationDialogProps {
  open: boolean;
  onClose: () => void;
  productId: number;
}

const validationSchema = Yup.object().shape({
  configKey: Yup.string().required(ValidationErrors.required),
  createParam: Yup.boolean(),
  campaignId: Yup.object()
    .nullable()
    .when('createParam', ([value]) =>
      value
        ? Yup.number().required(ValidationErrors.required)
        : Yup.mixed().notRequired(),
    ),
});

function DuplicationDialog({
  open = false,
  onClose,
  productId,
}: DuplicationDialogProps): ReactElement | null {
  const [modalOpen, setModalOpen] = useState(open);
  const [createConfigParam, setCreateConfigParam] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const campaignsBySingularSeason = useAppSelector((state) =>
    selectCampaignsBySingularSeason(state),
  );

  useEffect(() => {
    setModalOpen(open);
  }, [open]);
  const handleClose = () => {
    setModalOpen(false);
    setCreateConfigParam(false);
    onClose();
  };

  useEffect(() => {
    dispatch(fetchAllCampaigns());
  }, []);

  const handleSubmit = async (_form: FormData, values: FormikValues) => {
    const response = await dispatch(
      duplicateProduct({ id: productId, values }),
    ).unwrap();

    if (response.statusCode === 201 && response.createdId) {
      navigate(`/product/edit/${response.createdId}`);
    }
    handleClose();
  };

  if (!modalOpen) {
    return null;
  }

  const renderFieldset = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    submitForm,
    setFieldValue,
    isSubmitting,
  }: FormikProps<FormikValues>) => (
    <>
      <DialogTitle>
        <Translate>Duplicate Product</Translate>
      </DialogTitle>
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            paddingTop: 1,
          },
        }}
      >
        <Stack spacing={3}>
          <TextField
            label={<Translate>Config Key</Translate>}
            name="configKey"
            value={values.configKey ? values.configKey : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            // helperText={<ErrorMessage name="configKey" />}
            // error={touched.configKey && Boolean(errors.configKey)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={createConfigParam}
                onClick={() => {
                  const newValue = !createConfigParam;
                  if (!newValue) {
                    setFieldValue('configParam', undefined);
                  }
                  setFieldValue('createParam', !createConfigParam);
                  setCreateConfigParam(!createConfigParam);
                }}
              />
            }
            label={<Translate>Connect Campaign</Translate>}
          />
          {createConfigParam && (
            <>
              <Typography variant="h6">
                <Translate>Connect Campaign</Translate>
              </Typography>
              <TextField
                select
                name="campaignId"
                label={<Translate>Campaign</Translate>}
                value={values.campaignId ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={<ErrorMessage name="campaignId" />}
                error={Boolean(
                  getIn(touched, 'campaignId') && getIn(errors, 'campaignId'),
                )}
              >
                {campaignsBySingularSeason.map((campaign: Campaign) => (
                  <MenuItem key={campaign.id} value={campaign.id}>
                    <Translate>
                      {campaign.primaryText +
                        (campaign.crmId ? ` - ${campaign.crmId}` : '')}
                    </Translate>
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          <Translate>Cancel</Translate>
        </Button>
        <Button color="primary" onClick={submitForm} disabled={isSubmitting}>
          <Translate>Duplicate</Translate>
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={modalOpen} onClose={handleClose} fullWidth>
      <Form
        disableToolbar
        disableMargin
        initialValues={{ configKey: '', createParam: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        renderFieldset={renderFieldset}
      />
    </Dialog>
  );
}

export default DuplicationDialog;
