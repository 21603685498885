import React, { Component, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Translate from '../../../components/service/Translate';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { isEmpty, find, values } from 'lodash';
import ACL from '../../../components/ACL';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import { loadProduct, initForm, updateProduct } from '../actions';
import { loadTags } from '../../Affiliate/actions';
import PropTypes from 'prop-types';
import { outdateEntity } from '../../../actions';
import Form from '../../../components/Form';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import MailIcon from '@mui/icons-material/Mail';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import { makeChildProduct } from '../selectors';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { fetchCampaign } from '../../Campaign/actions';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid2';
import { compose } from 'redux';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

const CheckboxField = styled('div')({
  mt: 1,
  display: 'inline-flex',
  flexDirection: 'column',
});

const validationSchema = Yup.object().shape({
  configLabel: Yup.string().required(ValidationErrors.required),
  configKey: Yup.string().required(ValidationErrors.required),
  headline: Yup.string().nullable(),
  description: Yup.string().nullable(),
  footnote: Yup.string().nullable(),
  priceHeadline: Yup.string().nullable(),
  price: Yup.number()
    .transform((_value, originalValue) =>
      originalValue ? Number(originalValue.toString().replace(/,/, '.')) : 0,
    )
    .typeError(ValidationErrors.typeErrorNumberWithComma),
  priceAppendix1: Yup.string().nullable(),
  priceAppendix2: Yup.string().nullable(),
  priceAppendix3: Yup.string().nullable(),
  priority: Yup.number()
    .typeError(ValidationErrors.positiveIntNumber)
    .required(ValidationErrors.required),
  classification: Yup.string().required(ValidationErrors.requiredSelect),
  mapping: Yup.string().nullable(),
  channelEmailEnabled: Yup.bool(),
  channelWebEnablde: Yup.bool(),
  channelPrintEnabled: Yup.bool(),
  configGeneral: Yup.string()
    .nullable()
    .test('validJson', 'errMessage', function (value) {
      if (value) {
        try {
          JSON.parse(value);
        } catch (e) {
          return this.createError({
            message:
              'Das eingegebene JSON entspricht nicht den Vorgaben. ' +
              e.toString(),
            path: 'configGeneral',
          });
        }
      }
      return true;
    }),
  configPrint: Yup.string()
    .nullable()
    .test('validJson', 'errMessage', function (value) {
      if (value) {
        try {
          JSON.parse(value);
        } catch (e) {
          return this.createError({
            message:
              'Das eingegebene JSON entspricht nicht den Vorgaben. ' +
              e.toString(),
            path: 'configPrint',
          });
        }
      }
      return true;
    }),
  configEmail: Yup.string()
    .nullable()
    .test('validJson', 'errMessage', function (value) {
      if (value) {
        try {
          JSON.parse(value);
        } catch (e) {
          return this.createError({
            message:
              'Das eingegebene JSON entspricht nicht den Vorgaben. ' +
              e.toString(),
            path: 'configEmail',
          });
        }
      }
      return true;
    }),
  configWeb: Yup.string()
    .nullable()
    .test('validJson', 'errMessage', function (value) {
      if (value) {
        try {
          JSON.parse(value);
        } catch (e) {
          return this.createError({
            message:
              'Das eingegebene JSON entspricht nicht den Vorgaben. ' +
              e.toString(),
            path: 'configWeb',
          });
        }
      }
      return true;
    }),
  category: Yup.string()
    .oneOf([
      'layout',
      'product',
      'service',
      'template',
      'image',
      'vehicles',
      'other',
    ])
    .required(ValidationErrors.requiredSelect),
});

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.formikProps = null;
  }

  static propTypes = {
    loadProduct: PropTypes.func.isRequired,
    fetchCampaign: PropTypes.func.isRequired,
    initForm: PropTypes.func.isRequired,
    loadTags: PropTypes.func.isRequired,
    token: PropTypes.string,
    campaigns: PropTypes.object,
    campaignProducts: PropTypes.object,
    classifications: PropTypes.array,
    productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    values: {
      local: true,
    },
  };

  state = {
    refreshPreview: false,
    activeStep: 0,
  };

  componentDidMount = () => {
    const { loadProduct, initForm } = this.props;

    this.props.outdateEntity('product');
    const promises = [];
    if (this.props.productId && this.props.mode === 'edit') {
      promises.push(loadProduct(this.props.productId));
    } else {
      promises.push(initForm());
    }

    promises.push(this.props.fetchCampaign());
    promises.push(this.props.loadTags());
    this.setState({ dataLoading: true });

    Promise.all(promises).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.productId !== this.props.productId &&
      this.props.mode === 'edit'
    ) {
      this.setState({ dataLoading: true });
      Promise.all([
        this.props.loadProduct(this.props.productId, ['category']),
      ]).then(
        () => {
          this.setState({ dataLoading: false });
        },
        () => {
          this.setState({ dataLoading: false });
        },
      );
    }
  }

  handleSubmit = (form) => {
    const promises = [];
    const { updateProduct, productId } = this.props;
    const { refreshPreview } = this.state;

    promises.push(updateProduct(form, productId));

    return Promise.all(promises).then(
      (values) => {
        if (
          !(
            values &&
            values[0] &&
            values[0].type &&
            values[0].type.match(/_FAILURE/)
          )
        ) {
          this.props.outdateEntity('product');
          this.props.outdateEntity('season');
          if (!refreshPreview) {
            this.props.navigate('/product');
          } else {
            this.formikProps.setFieldValue('id', values[0].response.newId);
            this.formikProps.setFieldValue(
              'updateToken',
              values[0].response.newUpdateToken,
            );
            this.setState({ refreshPreview: false });
          }
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  refreshPreview = (props) => {
    const { submitForm } = props;

    this.setState({ refreshPreview: true });
    submitForm();
  };

  renderCampaign = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        {item.primaryText} ({item.campaignKey})
      </MenuItem>
    );
  };

  renderClassification = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        <span>
          <Translate>{item.primaryText}</Translate> ({item.primaryText})
        </span>
      </MenuItem>
    );
  };

  renderChannelEnable = (props) => {
    const { values, touched, errors, setFieldValue } = props;

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid size={4}>
            <CheckboxField>
              <FormCheckbox
                name="channelEmailEnabled"
                label={<Translate>E-Mail</Translate>}
                checked={values?.channelEmailEnabled ?? false}
                setFieldValue={setFieldValue}
                error={
                  touched?.channelEmailEnabled && errors?.channelEmailEnabled
                }
              />
            </CheckboxField>
          </Grid>
          <Grid size={4}>
            <CheckboxField>
              <FormCheckbox
                name="channelPrintEnabled"
                label={<Translate>Print</Translate>}
                checked={values?.channelPrintEnabled ?? false}
                setFieldValue={setFieldValue}
                error={
                  touched?.channelPrintEnabled && errors?.channelPrintEnabled
                }
              />
            </CheckboxField>
          </Grid>
          <Grid size={4}>
            <CheckboxField>
              <FormCheckbox
                name="channelWebEnabled"
                label={<Translate>Web</Translate>}
                checked={values?.channelWebEnabled ?? false}
                setFieldValue={setFieldValue}
                error={touched?.channelWebEnabled && errors?.channelWebEnabled}
              />
            </CheckboxField>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  renderGeneral = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;
    const { campaigns, productId, classifications } = this.props;
    const { refreshPreview } = this.state;

    return (
      <>
        <HiddenInput
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Config Label</Translate>}
              name="configLabel"
              defaultValue={values.configLabel ? values.configLabel : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.configLabel ? errors.configLabel : ''}
              error={touched.configLabel && Boolean(errors.configLabel)}
              disabled={!values.local}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Config Key</Translate>}
              name="configKey"
              defaultValue={values.configKey ? values.configKey : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.configKey ? errors.configKey : ''}
              error={touched.configKey && Boolean(errors.configKey)}
              disabled={!values.local}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="classification"
              label={<Translate>Classification</Translate>}
              onChange={handleChange('classification')}
              onBlur={handleBlur}
              helperText={touched.classification ? errors.classification : ''}
              error={touched.classification && Boolean(errors.classification)}
              value={values.classification ? values.classification : ''}
              disabled={!values.local}
            >
              {classifications.map(this.renderClassification)}
            </TextField>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="category"
              label={<Translate>Category</Translate>}
              onChange={handleChange('category')}
              onBlur={handleBlur}
              helperText={touched.category ? errors.category : ''}
              error={touched.category && Boolean(errors.category)}
              value={values.category ? values.category : ''}
              disabled={!values.local}
            >
              <MenuItem key={Math.random()} value={'layout'}>
                <Translate>module_category_layout</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'product'}>
                <Translate>module_category_product</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'service'}>
                <Translate>module_category_service</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'template'}>
                <Translate>module_category_template</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'image'}>
                <Translate>module_category_image</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'vehicles'}>
                <Translate>module_category_vehicles</Translate>
              </MenuItem>
              <MenuItem key={Math.random()} value={'other'}>
                <Translate>module_category_other</Translate>
              </MenuItem>
            </TextField>
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Headline</Translate>}
              name="headline"
              defaultValue={values.headline ? values.headline : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.headline ? errors.headline : ''}
              error={touched.headline && Boolean(errors.headline)}
              multiline
              rows={2}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Description</Translate>}
              name="description"
              defaultValue={values.description ? values.description : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
              multiline
              rows={10}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Footnote</Translate>}
              name="footnote"
              defaultValue={values.footnote ? values.footnote : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.footnote ? errors.footnote : ''}
              error={touched.footnote && Boolean(errors.footnote)}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Preisangaben</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              id={'price'}
              label={<Translate>Price</Translate>}
              defaultValue={
                !isEmpty('' + values.price) &&
                Number(values.price) === values.price
                  ? '' + values.price
                  : ''
              }
              name={'price'}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                props.setFieldValue(
                  'price',
                  props.values.price.toString().replace(/,/g, '.'),
                );
              }}
              helperText={touched.price ? errors.price : ''}
              error={touched.price && Boolean(errors.price)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              id={'priceKey'}
              label={<Translate>SPPS price key</Translate>}
              defaultValue={values.priceKey ? values.priceKey.toString() : ''}
              name={'priceKey'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priceKey ? errors.priceKey : ''}
              error={touched.priceKey && Boolean(errors.priceKey)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Price Headline</Translate>}
              name="priceHeadline"
              defaultValue={values.priceHeadline ? values.priceHeadline : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priceHeadline ? errors.priceHeadline : ''}
              error={touched.priceHeadline && Boolean(errors.priceHeadline)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Price Prefix</Translate>}
              name="pricePrefix"
              defaultValue={values.pricePrefix ? values.pricePrefix : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.pricePrefix ? errors.pricePrefix : ''}
              error={touched.pricePrefix && Boolean(errors.pricePrefix)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Price Appendix 1</Translate>}
              name="priceAppendix1"
              defaultValue={values.priceAppendix1 ? values.priceAppendix1 : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priceAppendix1 ? errors.priceAppendix1 : ''}
              error={touched.priceAppendix1 && Boolean(errors.priceAppendix1)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Price Appendix 2</Translate>}
              name="priceAppendix2"
              defaultValue={values.priceAppendix2 ? values.priceAppendix2 : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priceAppendix2 ? errors.priceAppendix2 : ''}
              error={touched.priceAppendix2 && Boolean(errors.priceAppendix2)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Price Appendix 3</Translate>}
              name="priceAppendix3"
              defaultValue={values.priceAppendix3 ? values.priceAppendix3 : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priceAppendix3 ? errors.priceAppendix3 : ''}
              error={touched.priceAppendix3 && Boolean(errors.priceAppendix3)}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Einstellungen</Translate>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Priority</Translate>}
              name="priority"
              defaultValue={values.priority ? values.priority : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.priority ? errors.priority : ''}
              error={touched.priority && Boolean(errors.priority)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Mapping (comma-separated list)</Translate>}
              name="mapping"
              defaultValue={values.mapping ? values.mapping : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.mapping ? errors.mapping : ''}
              error={touched.mapping && Boolean(errors.mapping)}
            />
          </Grid>
          <Grid size={12}>
            <FormControl
              fullWidth
              error={touched.campaigns && Boolean(errors.campaigns)}
              variant="filled"
            >
              <InputLabel>
                <Translate>Campaigns</Translate>
              </InputLabel>
              <Select
                name="campaigns"
                value={values.campaigns ? values.campaigns : []}
                renderValue={(selected) => {
                  let values = [];
                  selected.map((idx) => {
                    let campaign = find(campaigns, { id: idx });
                    if (campaign) {
                      values.push(campaign.primaryText);
                    }
                  });
                  return values.join(', ');
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!values.local}
                multiple
              >
                {Object.values(campaigns).map(this.renderCampaign)}
              </Select>
              <FormHelperText>
                {touched.locations ? errors.locations : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid size={12}>{this.renderChannelEnable(props)}</Grid>
          <Grid size={12}>
            <Typography variant="h6">
              <Translate>Produktvorschau</Translate>
            </Typography>
          </Grid>
          <Grid size={12}>
            {productId && !refreshPreview ? (
              <object
                data={Routing.generate(
                  'product_show_pdf',
                  { id: productId },
                  true,
                )}
                type="application/pdf"
                width={'100%'}
                height={'600px'}
              />
            ) : null}
          </Grid>
          <Grid size={12}>
            <Button
              onClick={() => this.refreshPreview(props)}
              color="primary"
              disabled={refreshPreview || !productId}
            >
              {refreshPreview ? <CircularProgress /> : 'Vorschau aktualisieren'}
            </Button>
          </Grid>
          <Grid size={12}>
            <div>
              <Button
                target="_blank"
                color="primary"
                href={Routing.generate(
                  'product_show_pdfConfig',
                  { id: productId },
                  true,
                )}
                disabled={!productId}
              >
                PDF-Konfiguration anzeigen
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  renderPrint = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Box sx={{ pt: 3, pb: 3 }}>
        <Grid size={12}>
          <TextField
            label={<Translate>Print Config</Translate>}
            key="configPrint"
            id="configPrint"
            name="configPrint"
            defaultValue={values.configPrint ? values.configPrint : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.configPrint ? errors.configPrint : ''}
            error={touched.configPrint && Boolean(errors.configPrint)}
            multiline
            rows={10}
          />
        </Grid>
      </Box>
    );
  };

  renderGeneralConfig = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Box sx={{ pt: 3, pb: 3 }}>
        <Grid size={12}>
          <TextField
            label={<Translate>Config</Translate>}
            key="configGeneral"
            id="configGeneral"
            name="configGeneral"
            defaultValue={values.configGeneral ? values.configGeneral : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.configGeneral ? errors.configGeneral : ''}
            error={touched.configGeneral && Boolean(errors.configGeneral)}
            multiline
            rows={10}
          />
        </Grid>
      </Box>
    );
  };

  renderEmail = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Box sx={{ pt: 3, pb: 3 }}>
        <Grid size={12}>
          <TextField
            label={<Translate>Email Config</Translate>}
            key="configEmail"
            id="configEmail"
            name="configEmail"
            defaultValue={values.configEmail ? values.configEmail : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.configEmail ? errors.configEmail : ''}
            error={touched.configEmail && Boolean(errors.configEmail)}
            multiline
            rows={10}
          />
        </Grid>
      </Box>
    );
  };

  renderWeb = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Box sx={{ pt: 3, pb: 3 }}>
        <Grid size={12}>
          <TextField
            label={<Translate>Web Config</Translate>}
            key="configWeb"
            id="configWeb"
            name="configWeb"
            defaultValue={values.configWeb ? values.configWeb : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.configWeb ? errors.configWeb : ''}
            error={touched.configWeb && Boolean(errors.configWeb)}
            multiline
            rows={10}
          />
        </Grid>
      </Box>
    );
  };

  getSteps = () => {
    return ['Allgemein', 'allg. Konfiguration', 'Print', 'E-Mail', 'Web'];
  };

  getStepIcon = (props) => {
    const icons = {
      1: (
        <IconButton
          onClick={() => this.setState({ activeStep: 0 })}
          aria-label="General"
          size="large"
        >
          <AppsIcon />
        </IconButton>
      ),
      2: (
        <IconButton
          onClick={() => this.setState({ activeStep: 1 })}
          aria-label="General"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      ),
      3: (
        <IconButton
          onClick={() => this.setState({ activeStep: 2 })}
          aria-label="General"
          size="large"
        >
          <MailIcon />
        </IconButton>
      ),
      4: (
        <IconButton
          onClick={() => this.setState({ activeStep: 3 })}
          aria-label="General"
          size="large"
        >
          <AlternateEmailIcon />
        </IconButton>
      ),
      5: (
        <IconButton
          onClick={() => this.setState({ activeStep: 4 })}
          aria-label="General"
          size="large"
        >
          <LanguageIcon />
        </IconButton>
      ),
    };

    return (
      <Box
        sx={{
          // backgroundColor: palette.accent1Color,
          zIndex: 1,
          // color: palette.white,
          width: 50,
          height: 50,
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icons[props.icon]}
      </Box>
    );
  };

  renderFieldSet = (props, activeStep) => {
    this.formikProps = props;
    switch (activeStep) {
      case 1:
        return this.renderGeneralConfig(props);
      case 2:
        return this.renderPrint(props);
      case 3:
        return this.renderEmail(props);
      case 4:
        return this.renderWeb(props);
      case 5:
      default:
        return this.renderGeneral(props);
    }
  };

  render() {
    const { productId, product, campaigns, token, onCancel } = this.props;
    const { dataLoading, activeStep } = this.state;
    const { ...defaultValues } = this.props.values;

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if ((productId && isEmpty(product)) || isEmpty(campaigns)) {
      return null;
    }

    if (!productId && isEmpty(token)) {
      return null;
    }

    if (isEmpty(product)) {
      defaultValues.updateToken = token;
      defaultValues.local = true;
      defaultValues.campaignProducts = [];
    }

    let headline = !isEmpty(product) ? (
      product.configLabel
    ) : (
      <Translate>New product</Translate>
    );

    return (
      <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
        <Form
          onCancel={onCancel}
          validationSchema={validationSchema}
          headline={headline}
          values={{}}
          initialValues={!isEmpty(product) ? product : defaultValues}
          onSubmit={this.handleSubmit}
          name="product"
          enableStepper
          formIcons={this.getStepIcon}
          formSteps={this.getSteps}
          activeStep={activeStep}
          renderFieldset={this.renderFieldSet}
        />
      </ACL>
    );
  }
}

function makeMapStateToProps() {
  const getChildProduct = makeChildProduct();

  return (state, props) => {
    const {
      entities: { campaign, product, tag, campaignProduct },
      forms: { product: form },
    } = state;

    const {
      params: { productId, mode },
    } = props.match;

    if (!isEmpty(product) && productId && product[productId]) {
      return {
        mode: mode,
        productId: productId ? productId : null,
        product: product[productId],
        token: !isEmpty(form) ? form.updateToken : null,
        childProduct: getChildProduct(state),
        campaigns: campaign,
        campaignProducts: campaignProduct,
        classifications: values(tag),
      };
    }

    return {
      mode: mode,
      productId: productId ? productId : null,
      token: !isEmpty(form) ? form.updateToken : null,
      campaigns: campaign,
      campaignProducts: campaignProduct,
      classifications: values(tag),
    };
  };
}

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    loadProduct,
    fetchCampaign,
    initForm,
    outdateEntity,
    loadTags,
    updateProduct,
  }),
);

export default enhance(ProductEdit);
