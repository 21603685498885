import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const duplicateProduct = createAsyncThunk(
  'product/duplicate',
  async (data: { id: EntityId; values: FormikValues }) => {
    const { id, values } = data;

    const response = await axios.post(
      Routing.generate('product_duplicate', { id }, true),
      { ...values },
      { withCredentials: true },
    );

    return {
      statusCode: response.status,
      createdId: response.data.id,
      message: response.data.message,
    };
  },
);
