import React, { ReactNode, JSX } from 'react';
import { Toolbar, Typography, Tooltip, IconButton, Box } from '@mui/material';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { AnyObject, Maybe, ObjectSchema } from 'yup';
import FormikPatchTouched from './FormikTouchPatched';
import FormStepper from './FormStepper';
import Translate from './service/Translate';
import { Spacer, Title, Actions } from './StyledElements/StyledElements';

type FormPropsBase<T extends Maybe<AnyObject>> = {
  activeStep?: number;
  disableSubmitButton?: boolean;
  disableToolbar?: boolean;
  enableStepper?: boolean;
  formIcons?: ReactNode;
  formSteps?: string[];
  headline?: string | ReactNode;
  loading?: boolean;
  onSubmit: (
    formData: FormData,
    data: FormikValues,
    actions: FormikHelpers<FormikValues>,
  ) => Promise<void>;
  renderFieldset: (props: FormikProps<FormikValues>) => ReactNode;
  showCancel?: boolean;
  initialValues: FormikValues;
  validationSchema?: ObjectSchema<T>; // Adjust the actual type
  disableMargin?: boolean;
};

type FormProps<T extends Maybe<AnyObject>> =
  | (FormPropsBase<T> & {
      disableToolbar: true;
      onCancel?: undefined; // Must be undefined
    })
  | (FormPropsBase<T> & {
      disableToolbar?: false; // Defaults to false if not provided
      onCancel: () => void; // Required when disableToolbar is false
    });

function Form<T extends Maybe<AnyObject>>({
  activeStep,
  disableSubmitButton,
  disableToolbar,
  enableStepper,
  formIcons,
  formSteps,
  headline,
  loading,
  onCancel,
  onSubmit,
  renderFieldset,
  showCancel = true,
  validationSchema,
  initialValues,
  disableMargin,
}: FormProps<T>): JSX.Element {
  const toFormData = (
    obj: FormikValues,
    form?: FormData,
    namespace?: string,
  ): FormData => {
    const fd = form || new FormData();
    let formKey;

    Object.keys(obj).map((property) => {
      if (
        Object.prototype.hasOwnProperty.call(obj, property) &&
        obj[property] != null
      ) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          toFormData(obj[property], fd, formKey);
        } else if (
          formKey.indexOf('file') !== -1 &&
          document.getElementsByName(`${formKey}`) &&
          document.getElementsByName(`${formKey}`)[0]
        ) {
          const fileInputElement = document.getElementsByName(
            `${formKey}`,
          )[0] as HTMLInputElement;
          if (fileInputElement.files) {
            fd.append(formKey, fileInputElement.files[0]);
          }
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
      return null;
    });
    return fd;
  };

  const submit = async (
    data: FormikValues,
    actions: FormikHelpers<FormikValues>,
  ) => {
    actions.setSubmitting(true);
    const form = toFormData(data);

    await onSubmit(form, data, actions);
    actions.setSubmitting(false);
  };

  const renderStepper = (props: FormikProps<FormikValues>) => (
    <FormStepper
      formikProps={props}
      renderStepContent={renderFieldset}
      formIcons={formIcons}
      activeStep={activeStep}
      formSteps={formSteps}
    />
  );

  const renderToolbar = (
    headline: ReactNode,
    loading: boolean = false,
    submitting: boolean = false,
  ) => (
    <Toolbar>
      <Title>
        <Typography variant="h5" id="tableTitle">
          {headline}
        </Typography>
      </Title>
      <Spacer />
      <Actions>
        <div>
          <Tooltip title={<Translate>Save</Translate>}>
            <span>
              <IconButton
                aria-label="Save"
                type="submit"
                color="primary"
                disabled={(loading || disableSubmitButton) ?? submitting}
                size="large"
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
          {showCancel ? (
            <Tooltip title={<Translate>Cancel</Translate>}>
              <span>
                <IconButton aria-label="Cancel" onClick={onCancel} size="large">
                  <CancelIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
        </div>
      </Actions>
    </Toolbar>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, actions) => submit(values, actions)}
    >
      {(props) => (
        // eslint-disable-next-line react/prop-types
        <form onSubmit={props.handleSubmit}>
          <FormikPatchTouched />
          {!disableToolbar
            ? renderToolbar(headline, loading, props.isSubmitting)
            : null}
          <Box sx={disableMargin ? {} : { mr: 3, ml: 3 }}>
            {enableStepper ? renderStepper(props) : renderFieldset(props)}
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default Form;
