import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from '@reduxjs/toolkit';
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import { Campaign } from './CampaignType';
import type { RootState } from '../../reducers';
import { fetchAllCampaignConfigParams } from '../CampaignConfigParam/CampaignConfigParamSlice';
import { Schemas } from '../../schemas';
import { GridParams } from '../../components/DataGrid/DataGridTypes';
import { convertGridParamsToRequestParams } from '../../components/DataGrid/EeloyDataGridUtils';

const campaignAdapter = createEntityAdapter<Campaign>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllCampaigns = createAsyncThunk(
  'campaign/fetchAll',
  async (data?: { gridParams?: GridParams }) => {
    const response = await axios.get(
      Routing.generate('campaign_index', null, true),
      {
        withCredentials: true,
        params: { ...convertGridParamsToRequestParams(data?.gridParams) },
      },
    );

    const normalized = normalize<
      schema.Array<Campaign>,
      {
        campaign: Record<string, Campaign>;
      }
    >(camelizeKeys(response.data.data), Schemas.CAMPAIGNS);

    return {
      entities: normalized.entities,
      pagination: response.data.pagination,
      paginationModel: data?.gridParams?.paginationModel,
      resultset: normalized.result,
      sortModel: data?.gridParams?.sortModel,
      filterModel: data?.gridParams?.filterModel,
    };
  },
);

export const fetchCampaignById = createAsyncThunk(
  'campaign/fetchById',
  async (data: { id: EntityId }) => {
    const { id } = data;

    const response = await axios.get(
      Routing.generate('campaign_show', { id }, true),
      { withCredentials: true },
    );
    const normalized = normalize<
      schema.Entity<Campaign>,
      {
        campaign: Record<string, Campaign>;
      }
    >(camelizeKeys(response.data.data), Schemas.CAMPAIGN);

    return { entities: normalized.entities };
  },
);

interface SliceState {
  updateToken?: string | null;
  resultset?: number[];
  paginationModel?: GridPaginationModel;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  pagination?: {
    total: number;
    limit: number;
    offset: number;
  };
}

const initialState: SliceState = {
  updateToken: null,
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState: campaignAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCampaigns.fulfilled, (state, action) => {
      const { campaign } = action.payload.entities;

      if (campaign) {
        campaignAdapter.upsertMany(state, campaign);
      }

      state.paginationModel = action.payload.paginationModel;
      state.filterModel = action.payload.filterModel;
      state.sortModel = action.payload.sortModel;
      state.resultset = action.payload.resultset;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(fetchAllCampaignConfigParams.fulfilled, (state, action) => {
      const { campaign } = action.payload.entities;

      if (campaign) {
        campaignAdapter.upsertMany(state, campaign);
      }
    });
    builder.addCase(fetchCampaignById.fulfilled, (state, action) => {
      const { campaign } = action.payload.entities;

      if (campaign) {
        campaignAdapter.upsertMany(state, campaign);
      }

      state.updateToken = null;
    });
  },
});

export const {
  selectAll: selectAllCampaigns,
  selectById: selectCampaignById,
  selectIds: selectCampaignIds,
} = campaignAdapter.getSelectors<RootState>((state) => state.campaign);

export default campaignSlice.reducer;
