const otherConditions = [
  {
    type: 'General',
    label: 'Aktives Kampagnen Tracking',
    description: 'Fragt ab, ob Kampagnen Tracking aktiv ist.',
    before: '{% if job.season.tracking and customer.dseTracking %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'Motiv 1',
    description: 'Config Abfrage - Cover 1 NE',
    before: '{% if car.getCoverTag(config) is same as ("cover_1") %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'Motiv 2',
    description: 'Config Abfrage - Cover 2 NE',
    before: '{% if car.getCoverTag(config) is same as ("cover_2") %}',
    after: '{% endif %}',
  },
];

export default otherConditions;
