import React, { Component } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { isEqual, values, isEmpty, pickBy } from 'lodash';
import { makeHeadLocations } from '../../Location/selectors_deprecated';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid2';

class Login extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    roles: PropTypes.array,
    locations: PropTypes.array,
    disabled: PropTypes.bool,
  };

  componentDidMount = () => {};

  shouldComponentUpdate = (nextProps) => {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.roles, this.props.roles) ||
      !isEqual(nextProps.disabled, this.props.disabled) ||
      !isEqual(nextProps.locations, this.props.locations) ||
      !isEqual(nextProps.touched, this.props.touched) ||
      !isEqual(nextProps.errors, this.props.errors)
    );
  };

  renderRoles = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        {<Translate>{item.primaryText}</Translate>}
      </MenuItem>
    );
  };

  renderLocations = (locationId) => {
    const { locations } = this.props;
    return (
      <MenuItem key={locations[locationId].id} value={locations[locationId].id}>
        <Translate>{locations[locationId].primaryText}</Translate>
      </MenuItem>
    );
  };

  render() {
    const {
      roles,
      locations,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      disabled,
    } = this.props;
    if (!isEmpty(roles) && !isEmpty(locations)) {
      return (
        <>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="role"
              label={<Translate>Rolle</Translate>}
              value={values.role ? values.role : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.role ? errors.role : ''}
              error={touched.role && Boolean(errors.role)}
              disabled={disabled}
            >
              {roles.map(this.renderRoles)}&gt;
            </TextField>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl
              error={touched.locations && Boolean(errors.locations)}
              variant="filled"
              fullWidth
            >
              <InputLabel>
                <Translate>Standort</Translate>
              </InputLabel>
              <Select
                label={<Translate>Standort</Translate>}
                name="locations"
                value={values.locations ? values.locations : []}
                renderValue={(selected) => {
                  let values = [];
                  selected.map((idx) =>
                    values.push(
                      Object.values(pickBy(locations, (el) => el.id === idx))[0]
                        .primaryText,
                    ),
                  );
                  return values.join(', ');
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                multiple
              >
                {Object.keys(locations).map(this.renderLocations)}
              </Select>
              <FormHelperText>
                {touched.locations ? errors.locations : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = () => {
  const getHeadLocations = makeHeadLocations();

  return (state, props) => {
    const {
      entities: { role },
    } = state;

    return {
      roles: values(role),
      locations: values(getHeadLocations(state, props)),
    };
  };
};

export default connect(mapStateToProps, {})(Login);
